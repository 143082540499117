








.terms
  padding-top 45px
  padding-bottom @padding-top
